@import "../node_modules/bootstrap/less/variables.less";
@import "mixins.less";

//
// Sub-Menus
// --------------------------------------------------

.dropdown-submenu > a:after {
  content: "";
  float: right;
  margin-right: -@caret-width-base * 2 - 2;
}

@media (min-width: @grid-float-breakpoint) {
  .dropdown-submenu {
    position: relative;

    .make-caret(@line-height-computed / 2 - @caret-width-base, left, top, bottom);

    .dropdown-menu {
      top: 0;
      left: 100%;
      margin-top: -6px;
      border-top-left-radius: 0;

      .dropup &,
      .navbar-fixed-bottom & {
        top: auto;
        bottom: 0;
        margin-top: 0px;
        margin-bottom: -6px;
        border-top-left-radius: @border-radius-base;
        border-bottom-left-radius: 0;
      }
    }
  }
}

@media (max-width: @grid-float-breakpoint-max) {
  .dropdown-submenu {
    .make-caret((@line-height-computed - @caret-width-base) / 2, top, left, right);

    .dropdown-menu {
      position: static;
      margin-top: 0;
      border: 0;
      box-shadow: none;
    }
  }

  .dropdown-menu > .dropdown-submenu {
    .dropdown > &,
    .btn-group > & {
      .make-nested-list(30px, 0, 4);
    }

    .navbar-nav > .dropdown > & {
      .make-nested-list(35px, 0, 4);
    }
  }
}
